<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="banks.data"
			sort-by="bank_code"
			class="elevation-1"
			dense
			:search="search"
			hide-default-footer
			:items-per-page="1000"
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-toolbar-title>PETNET Bank Accounts</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
					<vue-excel-xlsx
						:data="banks.data"
						:columns="columnHeaders"
						:file-name="module_name"
						>
						<v-btn icon alt class="mt-2" :color="banks.data.length=== 0 ? 'gray' : 'primary'" :disabled="banks.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
					</vue-excel-xlsx>
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
			<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(banks.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(banks.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{banks.current_page}} ( {{banks.from}} - {{banks.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!banks.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!banks.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
		</v-data-table>
		<create-bank v-if="role_access > 1" ></create-bank>
	</div>
</template>

<script>
import createBank from './createBank.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-bank': createBank
	},
	data() {
		return {
			module_name: 'Banks',
			role_access: 1,
			search: '',
		};
	},
	computed: {
		...mapGetters({
			banks: 'bank/banks',
			headers: 'bank/headers',
			columnHeaders: 'bank/columnHeaders',
			editedItem: 'bank/editedItem',
			defaultItem: 'bank/defaultItem',
			dialog: 'bank/dialog',
			editedIndex: 'bank/editedIndex',
			valid: 'bank/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('bank/getBanks');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		download () {
			//Download
		},
		editItem (item) {
			this.$store.dispatch('bank/setEditedIndex',this.banks.data.indexOf(item));
			this.$store.dispatch('bank/getBankDetails', item.id);
			//this.$store.dispatch('bank/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('bank/setDialog',true);
			this.$store.dispatch('bank/setValid',true);
			this.$store.dispatch('bank/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.bank_name + '(' + item.bank_acct + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('bank/setEditedIndex', this.banks.data.indexOf(item));
					this.$store.dispatch('bank/deleteBank',item);                              
				}
			});
		},
		changeNextData() {
			var nextPage = this.banks.current_page + 1;
			this.$store.dispatch('bank/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.banks.current_page - 1;
			this.$store.dispatch('bank/getDataPages', prevPage);
		}
	}
};
</script>
<style scoped>
</style>