<template>
    <v-dialog v-model="dialog" 
      persistent max-width="80%"
      scrollable
    >
      <template v-slot:activator="{ on }">
        <app-add-button v-bind:module="'bank'"></app-add-button>
      </template>
          <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }} </v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

        <v-card-text>
            <v-container grid-list-md>
            <v-layout wrap>

                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_acct"
                    :counter="15"
                    :rules="bank_acctRules"
                    label="Bank Account"
                    required
                    maxlength=15
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.acct_type"
                    :items="acctType"
                    :rules="[v => !!v || 'Please select Account Type']"
                    label="Account Type"
                    data-vv-name="acctType"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <!-- <v-text-field 
                    v-model="editedItem.acct_code"
                    :counter="7"
                    :rules="acct_codeRules"
                    label="Account Code"
                    required
                    maxlength=7
                ></v-text-field> -->
                <v-autocomplete
											v-model="editedItem.acct_code"
											:items="data"
											editable
											item-text="acct_desc"
											item-value="acct_code"
											label="Account Code"
										></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.rep_form"
                    label="Rep Form"
                    maxlength=8
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_name"
                    :counter="150"
                    :rules="bank_nameRules"
                    label="Bank Name"
                    required
                    maxlength=150
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_branch"
                    :rules="bank_branchRules"
                    :counter="30"
                    label="Bank Branch"
                    required
                    maxlength=30
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_address"
                    :rules="bank_addressRules"
                    :counter="50"
                    label="Bank Address"
                    required
                    maxlength=50
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.contact_person"
                    :counter="50"
                    label="Contact Person"
                    maxlength=50
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.cont_person_posn"
                    :counter="25"
                    label="Contact Person Position"
                    maxlength=25
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_code"
                    :rules="bank_codeRules"
                    :counter="4"
                    label="Bank Code"
                    maxlength=4
                    required
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.active"
                    :items="bankStatus"
                    :rules="[v => !!v || 'Please select Status']"
                    label="Status"
                    data-vv-name="bankStatus"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <!-- <v-select
                    v-model="editedItem.acct_clss"
                    :items="acctClass"
                    :rules="[v => !!v || 'Please select Account Class']"
                    label="Account Class"
                    data-vv-name="acctClass"
                    required
                ></v-select> -->
									<v-autocomplete
										v-model="editedItem.acct_clss"
										:items="currency"
										editable
										hide-details
                    :rules="[v => !!v || 'Please select Account Class']"
                    label="Account Class"
										item-text="cur_desc"
										item-value="cur_code"
									></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.for_forex"
                    :items="forForex"
                    label="For Forex"
                    :rules="defaultRules"
                    data-vv-name="forForex"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.for_treasury"
                    :items="forTreasury"
                    label="For Treasury"
                    data-vv-name="forTreasury"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_address1"
                    :counter="30"
                    label="Bank Address 1"
                    maxlength=30
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_address2"
                    :counter="30"
                    label="Bank Address 2"
                    maxlength=30
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-text-field 
                    v-model="editedItem.bank_address3"
                    :counter="30"
                    label="Bank Address 3"
                    maxlength=30
                ></v-text-field>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
            <v-btn color="red darken-1"  text @click="close">Cancel</v-btn>
          </v-card-actions>

          </v-card>
          </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components:{
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		isvalid: false,
		acct_codeRules: [
			v => !!v || 'Account Code is required',
			// v => (v && v.length <= 7) || 'Account Code must be 7 characters or less.',
		],
		bank_codeRules: [
			v => !!v || 'Bank Code is required',
			v => (v && v.length <= 4) || 'Bank Code must be 4 characters or less.',
		],
		bank_acctRules: [
			v => !!v || 'Bank Account is required',
			v => (v && v.length <= 15) || 'Bank Account must be 15 characters or less.',
		],
		bank_nameRules: [
			v => !!v || 'Bank Name is required',
			v => (v && v.length <= 150) || 'Bank Name must be 150 characters or less.',
		],
		bank_branchRules: [
			v => !!v || 'Bank Branch is required',
			v => (v && v.length <= 30) || 'Bank Branch must be 30 characters or less.',
		],
		bank_addressRules: [
			v => !!v || 'Bank Address is required',
		],
		defaultRules: [
			v => !!v || 'Required',
		],
		data: [],
		nextPage: '',
	}),

	computed: {
		...mapGetters({
			banks: 'bank/banks',
			bankStatus: 'bank/bankStatus',
			acctType: 'bank/acctType',
			forForex: 'bank/forForex',
			forTreasury: 'bank/forTreasury',
			acctClass: 'bank/acctClass',
			editedItem: 'bank/editedItem',
			defaultItem: 'bank/defaultItem',
			dialog: 'bank/dialog',
			editedIndex: 'bank/editedIndex',
			formTitle: 'bank/formTitle',
			valid: 'bank/valid',
			coas: 'coa/coas',
			currency: 'currency/currencies'
      
		}),
	}, 
	watch: {
		coas: {
			handler(){
				this.getData();
			}
		}
	},
	mounted(){
		this.$store.dispatch('coa/getCoas');
		this.$store.dispatch('currency/getCurrencies');
	},
	methods: {
		async getData(){
			this.data = this.coas.data;
			this.nextPage = this.coas.next_page_url;
			let page = 2;

			while (this.nextPage !== null){
				await this.$store.dispatch('coa/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.data.push(details);
					});
				});
				page++;
			}
		},
		validate () {
			if (this.$refs.form.validate()) {
				this.snackbar = true;
			}
		},
		reset () {
			this.$refs.form.reset();
		},
		resetValidation () {
			this.$refs.form.resetValidation();
		},
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('bank/setValid',true);
				this.$store.dispatch('bank/setDialog',false);
				this.$store.dispatch('bank/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
      
		save () {
			this.$store.dispatch('bank/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('bank/updateBank',this.editedItem);
              
				} else {
					this.$store.dispatch('bank/saveBank',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 
				3000);
			}
		},
	}
};
</script>