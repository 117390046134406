<template>
	<div class="home">
		<table-bank></table-bank>
  </div>
</template>

<script>
import tableBank from '@/components/pages/maint/bank/tableBank.vue';

export default {
	components: {
		'table-bank': tableBank
	}
};
</script>

<style>

</style>
